import { isUserConsent } from '../../core/js/user-consents.js';
import { provideCoreHeaderSessionAdapter } from '../../core/js/schibsted-account/core-header-adapter.js';
import { initUserSession } from '../../core/js/schibsted-account/index.server.js';
import { userDataStoreApi } from '../../core/js/user-data-store/api.client.js';

window.isUserConsent = isUserConsent;
window.userDataStoreApi = userDataStoreApi;

initUserSession();
provideCoreHeaderSessionAdapter();

let fcpDone = false,
  loadDone = false,
  done = false;

function handleLoad() {
  // Only continue once we have seen _both_ the 'load' event, and
  // first-contentful-paint (FCP) to ensure we're not competing for the main
  // thread before the page has even been displayed. On slower CPUs, FCP is
  // likely to happen long after 'load'.

  if (done) return;
  if (fcpDone && loadDone) {
    done = true;
    Promise.all([import('../js-chunks/on-load.js')]).catch((e) =>
      console.error(e),
    );
  }
}

const observer = new PerformanceObserver((entries) => {
  if (entries.getEntriesByName('first-contentful-paint').length > 0) {
    observer.disconnect();
    fcpDone = true;
    handleLoad();
  }
});

observer.observe({ type: 'paint', buffered: true });

window.addEventListener('load', () => {
  loadDone = true;
  handleLoad();
});
